<template>
  <div
    class="flycalDashboard flycalDashboard--list"
    :class="'flycalDashboard--' + dashboardType"
    :key="currentPath"
  >
    <div class="flycalDashboard__header">
      <div class="flycalDashboard__title">{{ subtitle.replace('-', ' ') }}</div>
      <router-link v-if="dashboardType === 'admin'" class="signUpDashboard__button" to="">
          <span
          @click="exportToExcel()"
          class="text">Export</span>
        </router-link>
    </div>
    <div class="flycalDashboard__table">
      <div class="flycalDashboard__table-header">
        <div class="flycalDashboard__table-cell">№</div>
        <div class="flycalDashboard__table-cell">Request number</div>
        <div class="flycalDashboard__table-cell">Flyer name</div>
        <div class="flycalDashboard__table-cell">Direction</div>
        <div class="flycalDashboard__table-cell">Departure</div>
        <div class="flycalDashboard__table-cell">Arrival</div>
        <div class="flycalDashboard__table-cell">Created on</div>
        <div class="flycalDashboard__table-cell">First request</div>
      </div>
      <div class="flycalDashboard__table-body js-customScroll">
        <vue-scroll :ops="ops">
          <div class="flycalDashboard__table-row" v-for="(item, index) of list" :key="index">
            <div class="flycalDashboard__table-cell">
              <div class="flycalDashboard__table-index">{{list.length - index}}</div>
            </div>
            <div class="flycalDashboard__table-cell">
              <span>{{item.requestNumber}}</span>
            </div>
            <div class="flycalDashboard__table-cell">
              <a
                class="flycalDashboard__table-name"
                to=""
                @click="showFlyerDetailsPopup(item.flyer, item.requestNumber)"
              >
                {{item.flyer.firstName + " " + item.flyer.lastName}}
              </a>
            </div>
            <div class="flycalDashboard__table-cell">
              <span>{{item.direction}}</span>
            </div>
            <div class="flycalDashboard__table-cell">
              <span>{{item.departureAirportCode ? item.departureAirportCode : item.departureAirport.split(",")[0]}}</span>
            </div>
            <div class="flycalDashboard__table-cell">
              <span>{{item.arrivalAirportCode ? item.arrivalAirportCode : item.arrivalAirport.split(",")[0]}}</span>
            </div>
            <div class="flycalDashboard__table-cell">
              {{moment(item.createdOn).format('M/D/YYYY')}}
            </div>
            <div class="flycalDashboard__table-cell">
              <span>{{item.isFirstRequest ? "YES" : ""}}</span>
            </div>
          </div>
        </vue-scroll>
      </div>
    </div>
    <FlyerDetailsPopup/>
  </div>
</template>

<script>
  import { flyCalAPI } from '@/api/flyer/flycal'
  import { reportsAPI } from '@/api/reports'
  import FlyerDetailsPopup from '@/components/common/modals/FlyerDetailsPopup'
  import moment from 'moment'
  import '../SignUpDashboard/SignUpDashboard.styl'
  import './FlycalRequests.styl'

  export default {
    props: ['dashboardType', 'subtitle'],
    name: 'flycalList',
    data: () => ({
      list: [],
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
    }),
    computed: {
      currentPath () {
        return this.$router.currentRoute.path
      },
    },
    components: {
      FlyerDetailsPopup,
    },
    async mounted () {
      const result = await flyCalAPI.getFlyCals();
      this.list = result.data;
      var dataLength = this.list.length;
        // Used to check if request is the first for a given user
        var accountsEncountered = [];
        for (let i = 1; i < dataLength + 1; i++) {
            if (!this.list[dataLength - i]) continue;
              // Need to check from last to first; reverse order
            var flyerId = this.list[dataLength - i].flyer.flyerNumber;
            if (!accountsEncountered.includes(flyerId)) {
                this.list[dataLength - i].isFirstRequest = true;

                accountsEncountered.push(flyerId);
            } else {
                this.list[dataLength - i].isFirstRequest = false;
            }
        }
    },
    methods: {
      moment,
      showFlyerDetailsPopup (account, number) {
        account.number = number;
        this.$modal.show('FlyerDetailsPopup', {additionalInfo: account});
      },
      async exportToExcel () {
        const reportType = this.$router.currentRoute.path.split('/').pop();
        const report = reportsAPI.flycal();

        const { data } = await report;

        const downloadLink = document.createElement('a');
        const blob = new Blob(['\ufeff', data]);
        const url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = `${reportType}_report_` + new Date().toLocaleDateString().replaceAll('/', '-') + '.csv';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      },
    },
  }
</script>
